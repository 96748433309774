import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as AffiliatesTypes from './types';

//
//

export class ManagerAffiliates extends ZidderResource<ManagerAffiliates> {
  protected classInstance = ManagerAffiliates;
  public pathBase = 'v1/managers/store/';

  //

  async listAffiliates(
    query?: AffiliatesTypes.AffiliatesListQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(this.pathBase + 'affiliates', {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<AffiliatesTypes.AffiliatesListResponse>();

    if (!result?.affiliates) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async getAffiliate({ id }: AffiliatesTypes.AffiliateQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + `affiliate/${id}`, {
      ...options,
    });
    const result = await response.json<AffiliatesTypes.AffiliateResponse>();

    if (!result?.affiliate) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async generateAffiliateCode(options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'affiliate/code', {
      ...options,
    });

    return await response.json<AffiliatesTypes.AffiliateCodeResponse>();
  }

  //

  async createAffiliate(
    payload: AffiliatesTypes.CreateAffiliatePayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + 'affiliate', {
      json: payload,
      ...options,
    });
    const result = await response.json<AffiliatesTypes.CreateAffiliateResponse>();

    return result;
  }

  //

  async editAffiliateCommission(
    payload: AffiliatesTypes.EditAffiliateCommissionPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(
      this.pathBase + `affiliate/${payload.affiliateId}/commission`,
      {
        ...options,
        json: payload.data,
      },
    );
    const result = await response.json<AffiliatesTypes.EditAffiliateCommissionResponse>();

    return result;
  }

  //

  async editAffiliatePersonalInfo(
    payload: AffiliatesTypes.EditAffiliatePersonalInfoPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(
      this.pathBase + `affiliate/${payload.affiliateId}/info`,
      {
        ...options,
        json: payload.data,
      },
    );
    const result = await response.json<AffiliatesTypes.EditAffiliatePersonalInfoResponse>();

    return result;
  }

  //

  // TODO:BACKEND @ahmed This Endpoint needs to be reviewed with the backend team due to
  // it returns false positive response (return message that user is deactivated but it's not)
  async toggleAffiliateStatus(
    payload: AffiliatesTypes.ToggleAffiliateStatusPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase + `affiliate/${payload.id}/status`, {
      ...options,
      searchParams: { status: payload.status },
    });
    const result = await response.json<AffiliatesTypes.ToggleAffiliateStatusResponse>();

    return result;
  }

  //

  async deleteAffiliate(
    id: AffiliatesTypes.DeleteAffiliatePayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.delete(this.pathBase + `affiliate/${id}`, {
      ...options,
    });
    const result = await response.json<AffiliatesTypes.DeleteAffiliateResponse>();

    return result;
  }

  //

  async deleteBulkAffiliates(
    payload: AffiliatesTypes.DeleteBulkAffiliatesPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.delete(this.pathBase + `affiliate/bulk`, {
      ...options,
      json: payload,
    });
    const result = await response.json<AffiliatesTypes.DeleteBulkAffiliateResponse>();

    return result;
  }
}
