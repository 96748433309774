import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as SeoTypes from './types';

//
//

export class ManagerSeo extends ZidderResource<ManagerSeo> {
  protected classInstance = ManagerSeo;
  public pathBase = 'v1/managers/store/';

  //

  async saveSeo(data: SeoTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.put(this.pathBase + 'seo', {
      json: data,
      ...options,
    });

    const result = await response.json<SeoTypes.Response>();

    return result;
  }
}
