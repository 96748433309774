import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as CategoriesTypes from './list.types';

//
//

export class ManagerCategories extends ZidderResource<ManagerCategories> {
  protected classInstance = ManagerCategories;
  public pathBase = 'v1/managers/store/categories';

  async list(query?: CategoriesTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(`${this.pathBase}`, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<CategoriesTypes.Response>();

    if (!result?.categories) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async delete(id: CategoriesTypes.DeletePayload, options?: ZidderRequestOptions) {
    const response = await this.client.delete(`${this.pathBase}/${id}/delete`, {
      ...options,
    });

    const result = await response.json<CategoriesTypes.DeleteResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
