import ky, { KyInstance, Options } from 'ky';
import { KyHeadersInit } from 'node_modules/ky/distribution/types/options';

import { Settings } from '../resources';
import * as Manager from '../resources/manager';
import { ZidderHeaders } from '../types';
import { zidderGetHeaders, parseErrors } from '../utils';

//
//
export class Zidder {
  protected readonly client: KyInstance;
  protected _headers: KyHeadersInit = {};

  public readonly head: KyInstance['head'];
  public readonly get: KyInstance['get'];
  public readonly post: KyInstance['post'];
  public readonly put: KyInstance['put'];
  public readonly patch: KyInstance['patch'];
  public readonly delete: KyInstance['delete'];

  public readonly managerAuth: Manager.ManagerAuth;
  public readonly managerAccount: Manager.ManagerAccount;
  public readonly managerProducts: Manager.ManagerProducts;
  public readonly managerTicketing: Manager.ManagerTicketing;
  public readonly managerZidpayTransactions: Manager.ManagerZidpayTransactions;
  public readonly managerOrders: Manager.ManagerOrders;
  public readonly managerCustomers: Manager.ManagerCustomers;
  public readonly managerCatalog: Manager.ManagerCatalog;
  public readonly managerCategories: Manager.ManagerCategories;
  public readonly managerAbandonedCarts: Manager.ManagerAbandonedCarts;
  public readonly managerZidpayPaymentLinks: Manager.ManagerZidpayPaymentLinks;
  public readonly managerCoupons: Manager.ManagerCoupons;
  public readonly managerAffiliates: Manager.ManagerAffiliates;
  public readonly managerSeo: Manager.ManagerSeo;
  public readonly managerSmsCampaign: Manager.ManagerSmsCampaign;
  public readonly managerZidpayDeposits: Manager.ManagerZidpayDeposits;
  public readonly managerZidpayDepositDetails: Manager.ManagerZidpayDepositDetails;
  public readonly settings: Settings;

  constructor(
    protected readonly options: Options,
    zidderHeaders?: ZidderHeaders,
  ) {
    if (!this.options?.headers) this.options.headers = {};
    if (!this.options?.hooks) this.options.hooks = {};
    if (!this.options?.hooks?.beforeRequest?.length) this.options.hooks.beforeRequest = [];
    if (!this.options?.hooks?.beforeError?.length) this.options.hooks.beforeError = [];

    this.options.hooks.beforeRequest.push((req, _options) => {
      (_options as Options & { _startTime: number })._startTime = new Date().getTime();

      Object.entries(this._headers).map(([key, value]) => {
        if (!req.headers.has(key)) req.headers.set(key, value);
      });
    });

    this.options.hooks.beforeError.push(error => {
      throw parseErrors(error);
    });

    this._headers = {
      'accept-language': 'en',
      ...this.options?.headers,
      ...zidderGetHeaders(zidderHeaders),
    };

    this.client = ky.create(this.options);

    this.head = this.client.head.bind(this.client);
    this.get = this.client.get.bind(this.client);
    this.post = this.client.post.bind(this.client);
    this.put = this.client.put.bind(this.client);
    this.patch = this.client.patch.bind(this.client);
    this.delete = this.client.delete.bind(this.client);

    this.managerAuth = new Manager.ManagerAuth(this.client);
    this.managerAccount = new Manager.ManagerAccount(this.client);
    this.managerProducts = new Manager.ManagerProducts(this.client);
    this.managerTicketing = new Manager.ManagerTicketing(this.client);
    this.managerZidpayTransactions = new Manager.ManagerZidpayTransactions(this.client);
    this.managerOrders = new Manager.ManagerOrders(this.client);
    this.managerCustomers = new Manager.ManagerCustomers(this.client);
    this.managerAbandonedCarts = new Manager.ManagerAbandonedCarts(this.client);
    this.managerZidpayPaymentLinks = new Manager.ManagerZidpayPaymentLinks(this.client);
    this.managerAffiliates = new Manager.ManagerAffiliates(this.client);
    this.managerSeo = new Manager.ManagerSeo(this.client);
    this.managerSmsCampaign = new Manager.ManagerSmsCampaign(this.client);
    this.managerCatalog = new Manager.ManagerCatalog(this.client);
    this.managerCategories = new Manager.ManagerCategories(this.client);
    this.managerZidpayDeposits = new Manager.ManagerZidpayDeposits(this.client);
    this.managerCoupons = new Manager.ManagerCoupons(this.client);
    this.managerZidpayDepositDetails = new Manager.ManagerZidpayDepositDetails(this.client);

    this.settings = new Settings(this.client);
  }

  public setHeadersGolbally(headers: ZidderHeaders) {
    this._headers = { ...this._headers, ...zidderGetHeaders(headers) };
  }
}
