import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as ApiTtypes from './api.types';
import type * as LoginTypes from './login.types';
import type * as LogoutTypes from './logout.types';

//
//

export class ManagerAuth extends ZidderResource<ManagerAuth> {
  protected classInstance = ManagerAuth;
  public pathBase = 'v1/managers/';

  //

  async validateEmail(data: LoginTypes.ValidateEmailPayload, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'otp-auth/send', {
      json: { action: 'login', ...data },
      ...options,
    });
    const result = await response.json<LoginTypes.AuthOtpEmailResponse>();

    if (!result?.auth_data) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async login(data: LoginTypes.LoginPasswordPayload, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'login', { json: data, ...options });
    const result = await response.json<LoginTypes.Response>();

    if (!result?.user?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async resetPasswordByToken(
    data: LoginTypes.ResetPasswordTokenPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + 'account/password/reset-by-token', {
      json: data,
      ...options,
    });
    const result = await response.json<ApiTtypes.ApiStatusResponseObjectType>();

    return result;
  }

  //

  async logout(data?: LogoutTypes.LogoutPayload, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'logout', { json: data, ...options });
    const result = await response.json<LogoutTypes.LogoutResponse>();

    return result;
  }

  async socialLogin(data: LoginTypes.SocialLoginPayload, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'social-login', {
      json: data,
      ...options,
    });
    const result = await response.json<LoginTypes.Response>();

    return result;
  }

  //

  async loginOtp(data: LoginTypes.PayloadOtp, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'otp-auth/login', {
      json: data,
      ...options,
    });
    const result = await response.json<LoginTypes.Response>();

    if (!result?.user?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async registerOtp(data: LoginTypes.PayloadOtp, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'otp-auth/register', {
      json: data,
      ...options,
    });
    const result = await response.json<LoginTypes.Response>();

    if (!result?.user?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  } //

  async accountPasswordResetEmail(
    data: LoginTypes.PayloadResetEmail,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + 'account/password/reset/email', {
      json: data,
      ...options,
    });

    const result = await response.json<LoginTypes.ResponseAccountPasswordResetEmail>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
