import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as OptionsTypes from './options.types';

//
//

export class ManagerCatalog extends ZidderResource<ManagerCatalog> {
  protected classInstance = ManagerCatalog;
  public pathBase = 'v1/';

  //

  async listAttributes(query?: OptionsTypes.ListAttributesQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'attributes', {
      ...options,
      searchParams: { ...query },
    });
    const result = await response.json<OptionsTypes.ListAttributesResponse>();

    return result;
  }

  //

  async getAttribute(id: OptionsTypes.GetAttributeQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + `attributes/${id}`, {
      ...options,
    });
    const result = await response.json<OptionsTypes.GetAttributeResponse>();

    return result;
  }

  //

  async createAttribute(
    payload?: OptionsTypes.CreateAttributePayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + 'attributes', {
      json: payload,
      ...options,
    });

    return await response.json<OptionsTypes.CreateAttributeResponse>();
  }

  //

  async updateAttribute(
    id: OptionsTypes.UpdateAttributePayload['id'],
    payload?: OptionsTypes.UpdateAttributePayload['data'],
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.patch(this.pathBase + `attributes/${id}`, {
      json: payload,
      ...options,
    });
    const result = await response.json<OptionsTypes.CreateAttributeResponse>();

    return result;
  }

  //

  async createAttributePreset(
    id: OptionsTypes.CreateAttributePresetPayload['attributeId'],
    payload?: OptionsTypes.CreateAttributePresetPayload['data'],
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + `attributes/${id}/presets`, {
      json: payload,
      ...options,
    });
    const result = await response.json<OptionsTypes.CreateAttributePresetResponse>();

    return result;
  }

  //

  async deleteAttribute(id: OptionsTypes.DeleteAttributePayload, options?: ZidderRequestOptions) {
    const response = await this.client.delete(this.pathBase + `attributes/${id}`, {
      ...options,
    });

    return response;
  }

  //

  async deleteAttributePreset(
    payload: OptionsTypes.DeleteAttributePresetPayload,
    options?: ZidderRequestOptions,
  ) {
    const { attributeId, presetId } = payload;
    const response = await this.client.delete(
      this.pathBase + `attributes/${attributeId}/presets/${presetId}`,
      { ...options },
    );

    return response;
  }
}
